import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

export default function SEOPage() {
    return (
        <Layout>
        <div id='SEOPage_page'>
            <h1>Search Engine Optimization</h1>
            <h2>Reach your digital goals.</h2>
            <Link to='/Pricing/'>
                <button id='SEOPage_header--button'>Plans & Pricing</button>
            </Link>
            <img alt='SEObanner.png' src='https://i.imgur.com/HPSc8Kn.png'></img>
            <div id='SEOServices_container'>
                <div>
                    <img alt='graphicon.png' src='https://i.imgur.com/SsBHt4F.png'></img>
                    <p>Content<br/>Optimization</p>
                </div>
                <div>
                    <img alt='graphicon.png' src='https://i.imgur.com/4ISMqPK.png'></img>
                    <p>Technical<br/>Enhancements</p>
                </div>
                <div>
                    <img alt='graphicon.png' src='https://i.imgur.com/MyDIRzh.png'></img>
                    <p>Link<br/>Building</p>
                </div>
                <div>
                    <img alt='graphicon.png' src='https://i.imgur.com/PXKU90Y.png'></img>
                    <p>Keyword<br/>Research</p>
                </div>
            </div>
            <div id='belowthefold_component'>
                <span>Below the Fold</span><br/>
                <h2>Content Optimization</h2>
                <p>We make sure the elements you see first, load first, so your<br/>website is always fast and responsive</p>
                <Link to='/WebDesign/'>
                    <button>Web Design &#62;</button>
                </Link>
            </div>
            <div id='technicalSEO_component'>
                <h2>Technical SEO Enhancements</h2>
                <p>Search engines favor websites that make it easier for the search engine to index them</p>
                <img alt='technicalSEOimg.png' src='https://i.imgur.com/qSEEnYi.png'></img>
            </div>
            <div id='linkBuilding_component'>
                <h2>Link Building</h2>
                <p>Search engines crawl through the links on your website and use those links to determine your position in search results.</p>
            </div>
            <div id='keywordResearch_component'>
                <img alt='keywordresearchimg.png' src='https://i.imgur.com/bwSweO5.png'></img>
                <div>
                    <h2>Keyword Research</h2>
                    <p>We research what words have the most effect on search result rank and make sure your website is always in the top 5.</p>
                    <Link to='/DigitalMarketing/'>
                        <button>Digital Marketing &#62;</button>
                    </Link>
                </div>
            </div>
            <Link id='SEOPage_approach--button' to='/Approach/'>
                <button>Our Approach</button>
            </Link>
        </div>
        </Layout>
    )
}