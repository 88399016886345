import React from 'react'
import { Link } from 'gatsby'

export default function Layout({ children }) {
    return (
        <div id='layout'>
            <div id='headerComponent'>
                <Link to='/'>
                    <img aria-label='logo-link' alt='header' id='header--image' src='https://i.imgur.com/MSIHx6g.png' />
                </Link>
                <div id='header_element--linksAndInfo'>
                    <div id='header_element--information'>
                        <div id='header_element--email'>info@chattmarketsolutions.com</div>
                        <div id='header_element--phone'>423-834-4308</div>
                        <Link id='header_element--consultLink' to='/Consult/'><span id='header_element--consultLinkText'>Free Consultation</span></Link>
                    </div>
                    <div id='header_element--links'>
                        <Link activeClassName='header--activeLink' className='header--link' to='/' exact='true'>Home</Link>
                        <Link activeClassName='header--activeLink' className='header--link' to='/Approach/'>Approach</Link>
                        <Link activeClassName='header--activeLink' className='header--link' to='/ContactUs/'>Contact Us</Link>
                        <div id='services_menu'>
                            <Link id='header_services--link' activeClassName='header--activeLink' className='header--link' to='/Services/'>Services <span>&#9660;</span></Link>
                            <div id='services_menu--component'>
                                <div>
                                    <Link to='/WebDesign/'>Web Design</Link>
                                </div>
                                <div>
                                    <Link to='/SEO/'>SEO</Link>
                                </div>
                                <div>
                                    <Link to='/DigitalMarketing/'>Digital Marketing</Link>
                                </div>
                            </div>
                        </div>
                        <Link activeClassName='header--activeLink' className='header--link' to='/Pricing/'>Pricing</Link>
                    </div>
                </div>
            </div>
            <div id='header_component--mobile'>
                <img aria-label='logo-link' alt='header' id='header_image--mobile' src='https://i.imgur.com/MSIHx6g.png' />
                <div id='menu_component'>
                    <img id='menu_component--img' alt='menuimg.png' src='https://i.imgur.com/w1yIETC.png'></img>
                    <div>
                        <div>
                            <Link activeClassName='header_link--mobileActive' to='/' exact='true'>Home</Link>
                            <Link activeClassName='header_link--mobileActive' to='/Approach/'>Approach</Link>
                            <Link activeClassName='header_link--mobileActive' to='/ContactUs/'>Contact Us</Link>
                            <Link to='/Services/'>Services</Link>
                            <div>
                                <Link activeClassName='header_link--mobileActive' to='/WebDesign/'>Web Design</Link>
                                <Link activeClassName='header_link--mobileActive' to='/SEO/'>SEO</Link>
                                <Link activeClassName='header_link--mobileActive' to='/DigitalMarketing/'>Digital Marketing</Link>
                                <Link activeClassName='header_link--mobileActive' to='/Consult/'>Free Consultation</Link>
                            </div>
                            <Link activeClassName='header_link--mobileActive' to='/Pricing/'>Pricing</Link>
                        </div>
                    </div>
                </div>
            </div>
            {children}
            <div id='footer--component'>
            <div id='footer_component--links'>
                <h5>LINKS</h5>
                <div></div>
                <Link to='/'>Home</Link>
                <Link to='/Services/'>Services</Link>
                <Link to='/Approach/'>Our Approach</Link>
                <Link to='/Pricing/'>Pricing</Link>
                <Link to='/ContactUs/'>Contact Us</Link>
            </div>
            <div id='footer_component--information'>
                <img alt='footerlogo.png' src='https://i.imgur.com/SzSB45q.png'></img>
                <div>
                    <img alt='footerlocation.png' src='https://i.imgur.com/wJHyyrq.png'></img>
                    <p>651 E 4th St<br></br> Chattanooga, TN 37403</p>
                </div>
                <div>
                    <img alt='footerphone.png' src='https://i.imgur.com/DITkjvD.png'></img>
                    <p>423-834-4308</p>
                </div>
                <div>
                    <img alt='footeremail.png' src='https://i.imgur.com/cssoM57.png'></img>
                    <p>info<br/>@chattmarketsolutions.com</p>
                </div>
            </div>
        </div>
        </div>
    )
}